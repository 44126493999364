import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { StateProvider } from './state';
import { Backdrop, Divider, Fade, Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from './utils/api';
import cookies from './cookies';
import { NAV_OPTIONS, ROLES } from './utils/constants';
import { makeStyles } from '@material-ui/styles';
import { lazyLoad } from './lazyLoad';

import AgeCheck from './components/AgeCheck';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LoadingError from './components/LoadingError';
import ScrollToTop from './components/ScrollToTop';

//=============General User Views and Components===============
import LandingPageView from './views/LandingPageView';
import StoresView from './views/StoresView';
import StoreDetailsView from './views/StoreDetailsView';
import ProductDetailsView from './views/ProductDetailsView';
import ClinicsView from './views/ClinicsView';
import ClinicDetailsView from './views/ClinicDetailsView';
import DeliveriesView from './views/DeliveriesView';
import DealsView from './views/DealsView';
import DealDetailsView from './views/DealDetailsView';
import BrandDetailsView from './views/BrandDetailsView';
import MapsPageView from './views/MapsPageView';
import LoginView from './views/LoginView';
import BrandsView from './views/BrandsView';
import UserSignUpView from './views/UserSignUpView';
import UserProfileView from './views/UserProfileView';
import UnauthorizedView from './views/UnauthorizedView';
import BlogsView from './views/BlogsView';
import IndividualBlogView from './views/IndividualBlogView';
import ForgotPasswordView from './views/ForgotPasswordView';
import ChangePasswordView from './views/ChangePasswordView';
import TermsConditonsView from './views/TermsConditionsView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import VerifiedEmailModal from './components/VerifiedEmailModal';
import ShortURLRedirector from './components/ShortURLRedirector';
import AdminLogsProvider from './components/adminLogs/context/AdminLogsContext';

// =============Lazy Loaded Views and components============================
//=============Client Portal Views and Components===============
const ClientPortalNavBar = lazyLoad('./components/ClientPortalNavBar');
const PreviewView = lazyLoad('./views/PreviewView');
const MyStoresView = lazyLoad('./views/MyStoresView');
const AddEditStoreView = lazyLoad('./views/AddEditStoreView');
const MyProductsView = lazyLoad('./views/MyProductsView');
const AddEditProductView = lazyLoad('./views/AddEditProductView');
const MyDealsView = lazyLoad('./views/MyDealsView');
const AddEditDealView = lazyLoad('./views/AddEditDealView');
const ClientMessagingView = lazyLoad('./views/ClientMessagingView');
const ClientMessageHistoryView = lazyLoad('./views/ClientMessageHistoryView');
const BookingSystem = lazyLoad('./components/Bookings/Index');

//=============Admin Portal Views and Components===============
const AdminHomeView = lazyLoad('./views/AdminHomeView');
const AdminPortalNavBar = lazyLoad('./components/AdminPortalNavBar');
const ManageFeaturedStoresView = lazyLoad('./views/ManageFeaturedStoresView');
const ManageFeaturedDealsView = lazyLoad('./views/ManageFeaturedDealsView');
const ManageUsersView = lazyLoad('./views/ManageUsersView');
const AddEditUserView = lazyLoad('./views/AddEditUserView');
const ManageBannersView = lazyLoad('./views/ManageBannersView');
const ManageListingOrderView = lazyLoad('./views/ManageListingOrderView');
const ManageBrandsView = lazyLoad('./views/ManageBrandsView');
const ManageProductOrderView = lazyLoad('./views/ManageProductOrderView');
const MailoutsView = lazyLoad('./views/MailoutsView');
const ManageMailOutsView = lazyLoad('./views/ManageMailOutsView');
const ManageFeaturedProductsView = lazyLoad('./views/ManageFeaturedProductsView');
const ManageVerifiedProductsView = lazyLoad('./views/ManageVerifiedProductsView');
const ManageNationalItemsView = lazyLoad('./views/ManageNationalItemsView');
const ManageAdsView = lazyLoad('./views/ManageAdsView');
const DealApprovalView = lazyLoad('./views/DealApprovalView');
const AdminLogsView = lazyLoad('./components/adminLogs/AdminLogsView.js');

const useStyles = makeStyles({
  pageContainer: {
    position: 'relative',
    // minHeight: '100vh',
  },
  pageContent: {
    paddingBottom: '2.5rem',
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  pageFooter: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '2.5rem',
  },
  backDrop: {
    background: 'rgba(25,25,25,0.85)',
  },
});

function App() {
  const classes = useStyles();

  const logout = () => {
    cookies.removeJWTAndUserID();
    setJWT(false);
    setUserId(false);
    setRole('');
  };

  const [JWT, setJWT] = useState(cookies.getJWT());
  const [userId, setUserId] = useState(cookies.getUserID());
  const [ageVerified, setAgeVerified] = useState(cookies.getAgeVerified());
  const [role, setRole] = useState(cookies.getRole());
  // const [region, setRegion] = useState(cookies.getRegion());

  const isGooglebot = window.navigator.userAgent.includes('Googlebot'); // SEO googlebot detection

  let location = cookies.getLocation();

  if (!location) {
    location = {
      province: 'Ontario',
      city: 'Toronto',
      //Should get the id from the location list, similar to the app.
      link: process.env.REACT_APP_ENV === 'devapi' ? '17' : '89', //if the link is not set, the nav bar will NOT update properly on cold starts
    };

    cookies.setLocation(location);
  }

  const [banners, setBanners] = useState([]);
  const [bannerError, setBannerError] = useState(false);
  const [bannersLoading, setBannersLoading] = useState(true);

  useEffect(() => {
    api
      .getAppData()
      .then((data) => {
        // const appData = data;
        // console.log("Retrieved App data:", appData);
      })
      .catch((error) => console.error(error));

    api
      .getBanners()
      .then((res) => {
        setBanners(res.data._embedded.banners);
        setBannersLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setBannerError(true);
        setBannersLoading(false);
      });

    if (isGooglebot) {
      setAgeVerified('true');
    }
  }, [isGooglebot]);

  const refreshBanners = async () => {
    api
      .getBanners()
      .then((res) => {
        setBannersLoading(true);
        setBanners(res.data._embedded.banners);
        setBannersLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setBannerError(true);
        setBannersLoading(false);
      });
  };

  const initialState = {
    location,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeLocation':
        return {
          ...state,
          location: action.newLocation,
        };

      default:
        return state;
    }
  };

  const [topLevelPath, setTopLevelPath] = useState(
    '/' +
      window.location.href.split('/')[3] +
      (window.location.href.split('/')[4] ? '/' + window.location.href.split('/')[4] : '')
  );

  const [previousTopLevel, setPreviousTopLevel] = useState('/');

  const barState = () => {
    // Simple but brute way of pulling out the path from the URL to be used for initially setting the navBarState. See if this can be refactored later (perhaps through useParams)
    if (topLevelPath.includes('/client') || topLevelPath.includes('/admin')) {
      return 0;
    }
    return NAV_OPTIONS.findIndex((option) => option.path === `/${topLevelPath.split('/')[1]}`);
  };

  const [navBarState, setNavBarState] = useState(barState());

  useEffect(() => {
    let state;

    if (topLevelPath.includes('/client') || topLevelPath.includes('/admin')) {
      state = 0;
    } else if (topLevelPath.includes('/products')) {
      if (
        previousTopLevel.includes('/stores') ||
        previousTopLevel.includes('/deliveries') ||
        previousTopLevel.includes('/delivery')
      ) {
        state = NAV_OPTIONS.findIndex((option) => option.path === previousTopLevel);
      } else state = 1;
    } else {
      state = NAV_OPTIONS.findIndex((option) => option.path === `/${topLevelPath.split('/')[1]}`);
    }

    if (isGooglebot) {
      setAgeVerified('true');
    }

    setNavBarState(state);
  }, [topLevelPath, previousTopLevel, isGooglebot]);

  const handleNavBarChange = (event, newValue, tab) => {
    setNavBarState(newValue);
  };

  const footerNavigate = (index) => {
    handleNavBarChange(null, index);
  };

  //Analytics - GA4 - only use in PROD Env
  if (process.env.REACT_APP_ENV === 'prodapi') ReactGA.initialize('G-7WG5D1Z6SS');

  const suspenseLoadingScreen = (
    <Modal open={true} onClose={() => {}}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </div>
    </Modal>
  );

  const isSuperAdmin = JWT && userId && role === ROLES.ROLE_SUPER_ADMIN;

  return (
    <ThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <AdminLogsProvider mode={isSuperAdmin ? 'admin' : null}>
          <Suspense fallback={suspenseLoadingScreen}>
            <div className="App">
              <div className={classes.pageContainer}>
                <Router>
                  <div className={classes.pageContent}>
                    {topLevelPath.includes('/admin') && role === ROLES.ROLE_SUPER_ADMIN && (
                      <AdminPortalNavBar
                        value={navBarState}
                        handleChange={handleNavBarChange}
                        logout={() => logout()}
                        role={role}
                      />
                    )}
                    {topLevelPath.includes('/client') &&
                      !topLevelPath.includes('/preview') &&
                      [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                        <ClientPortalNavBar
                          value={navBarState}
                          handleChange={handleNavBarChange}
                          logout={() => logout()}
                          role={role}
                        ></ClientPortalNavBar>
                      )}
                    {!topLevelPath.includes('/client') &&
                      !topLevelPath.includes('/admin') &&
                      !topLevelPath.includes('/signup') &&
                      !topLevelPath.includes('/forgotPassword') &&
                      !topLevelPath.includes('/users/changePassword') &&
                      !topLevelPath.includes('/login') && (
                        <NavBar
                          value={navBarState}
                          handleChange={handleNavBarChange}
                          currentPath={topLevelPath}
                          logout={() => logout()}
                        ></NavBar>
                      )}
                    <ScrollToTop
                      determineNavBar={() => {
                        setPreviousTopLevel(topLevelPath);
                        setTopLevelPath(
                          '/' +
                            window.location.href.split('/')[3] +
                            (window.location.href.split('/')[4] ? '/' + window.location.href.split('/')[4] : '')
                        );
                      }}
                    >
                      <Switch>
                        <Route exact path={`/q`} component={(props) => <ShortURLRedirector props={props} />} />
                        {(!JWT || !userId || !role) &&
                          (topLevelPath.includes('/admin') || topLevelPath.includes('/client')) && (
                            <LoginView
                              setJWT={(jwt) => setJWT(jwt)}
                              setUserId={(id) => setUserId(id)}
                              setRole={(role) => {
                                setRole(role);
                                cookies.setRole(role);
                              }}
                            ></LoginView>
                          )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/new"
                            component={(props) => <AddEditStoreView brand adminCreateMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/new"
                            component={(props) => <AddEditStoreView adminCreateMode {...props} />}
                          />
                        )}
                        {/* ao: search user by username Route  */}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/users/search/:usernameText"
                            component={(props) => (
                              <ManageUsersView
                                search={true}
                                usernameText={props.match.params.usernameText}
                                {...props}
                              />
                            )}
                          />
                        )}
                        {/* ao: when searchbox is empty don't search: */}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/users/search"
                            component={(props) => <ManageUsersView search={false} usernameText="" {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/users/new" component={(props) => <AddEditUserView {...props} />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/users/:id"
                            component={(props) => <AddEditUserView editMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/users" component={(props) => <ManageUsersView {...props} />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/messaging/history"
                            component={(props) => <ClientMessageHistoryView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/messaging" component={(props) => <ClientMessagingView {...props} />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/logs" component={(props) => <AdminLogsView />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/dealapproval" component={(props) => <DealApprovalView {...props} />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/manage-product-ordering"
                            component={(props) => <ManageProductOrderView brand adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/manage-featured-products"
                            component={(props) => <ManageFeaturedProductsView brand adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/manage-verified-products"
                            component={(props) => <ManageVerifiedProductsView adminMode {...props} />}
                          />
                        )}
                        {/* ao: search store by name route */}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/search/:nameText"
                            component={(props) => (
                              <MyStoresView search={true} nameText={props.match.params.nameText} adminMode {...props} />
                            )}
                          />
                        )}
                        {/* ao: when searchbox is empty don't search: */}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/search"
                            component={(props) => <MyStoresView search={false} nameText="" adminMode {...props} />}
                          />
                        )}
                        {/*  */}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/manage-product-ordering"
                            component={(props) => <ManageProductOrderView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/deals/new"
                            component={(props) => <AddEditDealView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/deals/:dealId"
                            component={(props) => <AddEditDealView adminMode editMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/deals"
                            component={(props) => <MyDealsView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/products/new"
                            component={(props) => <AddEditProductView adminMode brand {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/products/new"
                            component={(props) => <AddEditProductView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/products/:productId"
                            component={(props) => <AddEditProductView adminMode editMode brand {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/products/:productId"
                            component={(props) => <AddEditProductView adminMode editMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id/products"
                            component={(props) => <MyProductsView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id/products"
                            component={(props) => <MyProductsView adminMode brand {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/stores/:id"
                            component={(props) => <AddEditStoreView adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/stores" component={(props) => <MyStoresView adminMode {...props} />} />

                          /* []Old Route, replaced by deliveries and storefronts (to separate the lists), still active
                          incase of backlinks */
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands/:id"
                            component={(props) => <AddEditStoreView brand adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/brands"
                            component={(props) => <MyStoresView brand adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/deliveries"
                            component={(props) => <MyStoresView routeType="delivery" adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/storefronts"
                            component={(props) => <MyStoresView routeType="storefront" adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/clinics"
                            component={(props) => <MyStoresView routeType="clinic" adminMode {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/featured-products"
                            component={(props) => <ManageFeaturedProductsView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/latest-popular-brands"
                            component={(props) => <ManageBrandsView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/latest-popular-mailouts"
                            component={(props) => <ManageMailOutsView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/featured-national"
                            component={(props) => <ManageNationalItemsView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/listing-order"
                            component={(props) => <ManageListingOrderView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/featured-stores"
                            component={(props) => <ManageFeaturedStoresView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/featured-deals"
                            component={(props) => <ManageFeaturedDealsView {...props} />}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin/ads" component={(props) => <ManageAdsView {...props} />} />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route
                            path="/admin/banners"
                            component={(props) => (
                              <ManageBannersView
                                banners={banners}
                                bannerError={bannerError}
                                bannersLoading={bannersLoading}
                                refreshBanners={() => refreshBanners()}
                                {...props}
                              />
                            )}
                          />
                        )}
                        {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                          <Route path="/admin" component={(props) => <AdminHomeView {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/preview/stores/:id/deals/:dealId"
                            component={(props) => <PreviewView deal {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route path="/client/preview/stores/:id" component={(props) => <PreviewView {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/preview/clinics/:id"
                            component={(props) => <PreviewView clinic {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/preview/brands/:id"
                            component={(props) => <PreviewView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route path="/client/preview/:id" component={(props) => <PreviewView {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/manage-product-ordering"
                            component={(props) => <ManageProductOrderView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/manage-featured-products"
                            component={(props) => <ManageFeaturedProductsView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/manage-verified-products"
                            component={(props) => <ManageVerifiedProductsView {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/manage-product-ordering"
                            component={(props) => <ManageProductOrderView {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/products/new"
                            component={(props) => <AddEditProductView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/products/new"
                            component={(props) => <AddEditProductView {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/products/:productId"
                            component={(props) => <AddEditProductView editMode brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/products/:productId"
                            component={(props) => <AddEditProductView editMode {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/products"
                            component={(props) => <MyProductsView {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id/products"
                            component={(props) => <MyProductsView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/deals/new"
                            component={(props) => <AddEditDealView {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/deals/:dealId"
                            component={(props) => <AddEditDealView editMode {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route path="/client/stores/:id/deals" component={(props) => <MyDealsView {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/new"
                            component={(props) => <AddEditStoreView brand {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route path="/client/stores/new" component={(props) => <AddEditStoreView {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id/create-copy"
                            component={(props) => <AddEditStoreView copyMode {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route
                            path="/client/brands/:id"
                            component={(props) => <AddEditStoreView brand editMode {...props} />}
                          />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                          <Route path="/client/brands" component={(props) => <MyStoresView brand {...props} />} />
                        )}
                        {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                          <Route
                            path="/client/stores/:id"
                            component={(props) => <AddEditStoreView editMode {...props} />}
                          />
                        )}
                        {JWT &&
                          userId &&
                          [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                            <Route
                              path="/client/stores"
                              component={(props) => <MyStoresView brand={role === ROLES.ROLE_BRAND_ADMIN} {...props} />}
                            />
                          )}
                        {JWT &&
                          userId &&
                          [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                            <Route
                              path="/client/advertise"
                              component={(props) => <BookingSystem userID={userId} {...props} />}
                            />
                          )}
                        {JWT &&
                          userId &&
                          [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN, ROLES.ROLE_BRAND_ADMIN].includes(role) && (
                            <Route
                              path="/client"
                              component={(props) => <MyStoresView brand={role === ROLES.ROLE_BRAND_ADMIN} {...props} />}
                            />
                          )}
                        {JWT &&
                          userId &&
                          [
                            ROLES.ROLE_SUPER_ADMIN,
                            ROLES.ROLE_STORE_ADMIN,
                            ROLES.ROLE_BRAND_ADMIN,
                            ROLES.ROLE_USER,
                          ].includes(role) && (
                            <Route
                              path="/profile"
                              component={(props) => <UserProfileView {...props} logout={logout} />}
                            />
                          )}
                        <Route
                          path={'/signup'}
                          component={(props) => (
                            <UserSignUpView
                              setRole={(role) => {
                                setRole(role);
                                cookies.setRole(role);
                              }}
                              changeNavBar={() => {
                                setPreviousTopLevel('/');
                                setTopLevelPath('/');
                              }}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          path={'/login'}
                          component={(props) => (
                            <LoginView
                              setJWT={(jwt) => setJWT(jwt)}
                              setUserId={(id) => setUserId(id)}
                              setRole={(role) => {
                                setRole(role);
                                cookies.setRole(role);
                              }}
                              loginRoute={true}
                              {...props}
                            ></LoginView>
                          )}
                        />
                        <Route path={'/users/confirmEmail'}>
                          {bannersLoading === false && <VerifiedEmailModal />}
                          {/* Added shortcircuit as changes in bannersloading would cause multiple verificaiton POSTs */}
                        </Route>
                        <Route
                          path={'/forgotPassword'}
                          component={(props) => <ForgotPasswordView {...props}></ForgotPasswordView>}
                        />
                        <Route
                          path={'/users/changePassword'}
                          component={(props) => <ChangePasswordView {...props}></ChangePasswordView>}
                        />
                        <Route
                          path={`/terms-and-conditions`}
                          component={(props) => <TermsConditonsView {...props} />}
                        />
                        <Route path={`/privacy-policy`} component={(props) => <PrivacyPolicyView {...props} />} />
                        <Route
                          path={`/blog/top-canadian-cannabis-brands`}
                          component={(props) => <IndividualBlogView id="top-canadian-cannabis-brands" />}
                        />
                        <Route
                          path={`/blog/how-to-start-a-cannabis-business-in-ontario`}
                          component={(props) => <IndividualBlogView id="how-to-start-a-cannabis-business-in-ontario" />}
                        />
                        <Route
                          path={`/blog/dispensary-marketing-plan`}
                          component={(props) => <IndividualBlogView id="dispensary-marketing-plan" />}
                        />
                        <Route
                          path={`/blog/newsletter-september-2023`}
                          component={(props) => <IndividualBlogView id="newsletter-september-2023" />}
                        />
                        {/*====================== Redirects from OLD blog post to delivery page (containing the content)================ */}
                        <Route
                          path={`/blog/weed-delivery-calgary`}
                          component={(props) => <Redirect to="/delivery/alberta/calgary" />}
                        />
                        <Route
                          path={`/blog/weed-delivery-edmonton`}
                          component={(props) => <Redirect to="/delivery/alberta/edmonton" />}
                        />
                        <Route
                          path={`/blog/weed-delivery-montreal`}
                          component={(props) => <Redirect to="/delivery/quebec/montreal" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-brampton`}
                          component={(props) => <Redirect to="/delivery/ontario/brampton" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-guelph`}
                          component={(props) => <Redirect to="/delivery/ontario/guelph" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-hamilton`}
                          component={(props) => <Redirect to="/delivery/ontario/hamilton" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-markham`}
                          component={(props) => <Redirect to="/delivery/ontario/markham" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-ottawa`}
                          component={(props) => <Redirect to="/delivery/ontario/ottawa" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-vancouver`}
                          component={(props) => <Redirect to="/delivery/british-columbia/vancouver" />}
                        />{' '}
                        <Route
                          path={`/blog/weed-delivery-windsor`}
                          component={(props) => <Redirect to="/delivery/ontario/windsor" />}
                        />
                        {/* ===================================================================================================== */}
                        <Route path={`/blog`} component={(props) => <BlogsView />} />
                        {/* <Route
                          path="/brands/:storeId/products/:id"
                          component={(props) => (
                            <ProductDetailsView brand {...props} />
                          )}
                          exact
                        /> */}
                        <Route
                          path="/stores/:storeId/products/:id"
                          component={(props) => <ProductDetailsView {...props} />}
                        />
                        <Route
                          path="/stores/:province/:city/:storeSlug/:id/:productSlug"
                          component={(props) => <ProductDetailsView {...props} />}
                          exact
                        />
                        {/* ao: SDAW-1044 - Product route when store unkonwn - Global search */}
                        <Route
                          path="/products/:id/:productSlug"
                          component={(props) => <ProductDetailsView noStore {...props} />}
                          exact
                        />
                        <Route //Brand Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'Brands').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route path={`${url}`} component={(props) => <BrandsView {...props} />} exact />
                              <Route
                                path={`${url}/:id`}
                                component={(props) => (
                                  <BrandDetailsView handleNavBarChange={handleNavBarChange} {...props} />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:id/:storeSlug`}
                                component={(props) => (
                                  <BrandDetailsView handleNavBarChange={handleNavBarChange} {...props} />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:storeSlug/:id/:productSlug`}
                                component={(props) => <ProductDetailsView brand {...props} />}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // Deals Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'Deals').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <DealsView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city`}
                                component={(props) => (
                                  <DealsView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:id`}
                                /* base deal URLS /deals/:id, should redirect to /deals/:province/:city/:id, for SEO
                                  Convert to short url to go to proper page */
                                component={(props) => <Redirect to={`/q?d=${props.match.params.id}`} />}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city/:id/:dealSlug`}
                                component={(props) => (
                                  <DealDetailsView handleNavBarChange={handleNavBarChange} {...props}></DealDetailsView>
                                )}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // Deliveries Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'Deliveries').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <DeliveriesView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city`}
                                component={(props) => (
                                  <DeliveriesView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route path={`${url}/:id`} component={(props) => <StoreDetailsView {...props} />} exact />
                              <Route
                                path={`${url}/:province/:city/:id/`}
                                component={(props) => <StoreDetailsView {...props} />}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city/:id/:storeSlug`}
                                component={(props) => <StoreDetailsView {...props} />}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // "Delivery" Routes
                          path={'/delivery'}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <DeliveriesView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city`}
                                component={(props) => (
                                  <DeliveriesView
                                    // city={city}
                                    // province={prov}
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // Mailout Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'MailOuts').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <MailoutsView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />

                              <Route path={`${url}/:id`} component={(props) => <StoreDetailsView {...props} />} exact />
                              <Route
                                path={`${url}/:id/:storeSlug`}
                                component={(props) => <StoreDetailsView {...props} />}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // Storefront Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'Storefronts').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <StoresView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city`}
                                component={(props) => (
                                  <StoresView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />
                              <Route path={`${url}/:id`} component={(props) => <StoreDetailsView {...props} />} exact />
                              {/* /:id maybe unessecary; but may be for old back links (gets re routed to home)*/}
                              <Route
                                path={`${url}/:province/:city/:id`}
                                component={(props) => <StoreDetailsView {...props} />}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city/:id/:storeSlug`}
                                component={(props) => <StoreDetailsView {...props} />}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route // Clinic Region Free Routes
                          path={NAV_OPTIONS.find((option) => option.name === 'Clinics').path}
                          render={({ match: { url } }) => (
                            <>
                              <Route
                                path={`${url}`}
                                component={(props) => (
                                  <ClinicsView
                                    banners={banners}
                                    bannerError={bannerError}
                                    bannersLoading={bannersLoading}
                                    {...props}
                                  />
                                )}
                                exact
                              />

                              <Route path={`${url}/:id`} component={(props) => <StoreDetailsView {...props} />} exact />

                              <Route
                                path={`${url}/:id/:storeSlug`}
                                component={(props) => <ClinicDetailsView {...props} />}
                                exact
                              />
                              <Route
                                path={`${url}/:province/:city/:id/:storeSlug`}
                                component={(props) => <ClinicDetailsView {...props} />}
                                exact
                              />
                            </>
                          )}
                        />
                        <Route
                          path={'/404error'}
                          component={(props) => (
                            <LoadingError onClick={() => props.history.push('/deliveries')} browse="deliveries" />
                          )}
                          exact
                        />
                        <Route path={'/maps'}>
                          <MapsPageView admin={role === ROLES.ROLE_SUPER_ADMIN ? true : false}></MapsPageView>
                        </Route>
                        <Route path={NAV_OPTIONS.find((option) => option.name === 'Home').path}>
                          {(topLevelPath.includes('/admin') || topLevelPath.includes('/client')) && (
                            <UnauthorizedView />
                          )}
                          {!topLevelPath.includes('/admin') && !topLevelPath.includes('/client') && (
                            <LandingPageView handleNavBarChange={handleNavBarChange}></LandingPageView>
                          )}
                        </Route>
                      </Switch>
                    </ScrollToTop>
                  </div>
                  <div className={classes.pageFooter}>
                    <Divider />
                    <Footer navigate={(index) => footerNavigate(index)}></Footer>
                  </div>
                </Router>
                {/* {ageVerified !== "true" && (
              <AgeCheck
                confirm={() => setAgeVerified("true")}
                region={region}
              ></AgeCheck>
            )} */}

                <Modal
                  className={classes.modal}
                  open={ageVerified !== 'true'}
                  onClose={() => {}}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop,
                    },
                    timeout: 500,
                  }}
                >
                  <Fade in={ageVerified !== 'true'}>
                    {/* <div className={classes.modalPaper}></div> */}
                    <AgeCheck confirm={() => setAgeVerified('true')} />
                  </Fade>
                </Modal>
              </div>
            </div>
          </Suspense>
        </AdminLogsProvider>
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
